<template>
  <div>
    <div
      class="h-60 flex justify-center items-center cursor-pointer bout"
      :style="{'height': height, 'border-radius': radius, 'border': border, 'background-color': background, 'color': color, 'font-weight': weight, 'font-size': size }"
      @click="continuer"
    >
      <icon
        v-if="icon !== null"
        :data="icon"
        :height="iconHeight"
        :width="iconWidth"
        class="mr-2"
        original
      />
      <div v-if="!charge">
        {{ label }}
      </div>
      <easy-spinner
        v-if="charge"
        type="dots"
        size="30"
      />
    </div>
  </div>
</template>

<script>export default {
  name: 'CedricDivider',
  props: {
    height: {
      type: String,
      default: '50px'
    },
    iconHeight: {
      type: Number,
      default: 20
    },
    iconWidth: {
      type: Number,
      default: 20
    },
    weight: {
      type: String,
      default: '400'
    },
    icon: {
      type: Object,
      default: null
    },
    radius: {
      type: String,
      default: '10px'
    },
    border: {
      type: String,
      default: 'none'
    },
    background: {
      type: String,
      default: '#5138EE'
    },
    color: {
      type: String,
      default: '#fff'
    },
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '18px'
    },
    charge: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    continuer(){
      this.$emit('info', this.label)
      this.$emit('oga', true)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";
.bout:hover{
  border: 1px solid gray!important;
}
.svg-icon {
  fill: transparent;
}
</style>
