<template>
  <section
      class="add-team overflow-y-scroll w-full z-10"
      @click="clickOnBackground"
  >
    <div
        class="popup-container w-full flex items-center justify-center"
    >
      <div
          class="popup w-full"
          @click.prevent.stop
      >
        <slot
            class="heartbeat overflow-y-scroll popup-element"
            @click.prevent.stop
        />
      </div>
    </div>
  </section>
</template>

<script>export default {
  name: 'PopupBase',
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
    clickOnBackground () {
      this.$emit('clickOnBackground', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/mixin";
.add-team {
  background-color: rgba(0, 0, 0, 0.70);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  max-height: 100vh;
  overflow: scroll !important;
  z-index: 999;
  //height: 50%;
  //overflow: visible !important;

}
.popup-container {
  position: relative;
  display: flex;
  vertical-align: middle;
  max-height: 100vh;
  padding: 5rem;
}
.popup {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.400, 0.600, 0.800, 1) both;
  animation: scale-up-center 0.4s cubic-bezier(0.400, 0.600, 0.800, 1) both;
  //overflow: visible !important;
}
.popup-element {
  height: 10%;
}

@keyframes bounce{
  from {transform: translateY(-2px);}
  to   {transform: translateY(-20px);}
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@media screen and (max-width: 600px) {
  .popup {
    width: 100%;
  }
  .popup-container {
    padding: 0rem
  }
}
</style>
