export default {
    baseURL: 'https://test.leslunettesoho.com',
    login: '/api/v1/auth/login',
    allAgency: '/api/v1/agency/get-agency',
    swich: '/api/v1/auth/switch-agency',
    updatePass: '/api/v1/auth/update-password',
    getProduct: '/api/v1/product/get-products',
    allDevis: '/api/v1/estimate/sell/get-estimate',
    allCompany: '/api/v1/insurance/get-insurance',
    allExport: '/api/v1/accounting/get-supplier-exported-file',
    allSupplier: '/api/v1/accounting/get-export-stats',
    upload: '/api/v1/utility/upload',
    createFacture: '/api/v1/accounting/create-internal-invoice',
    supplierInvoice: '/api/v1/accounting/get-supplier-internal-invoice',
    getSupplier: '/api/v1/supplier/get-supplier',
    wallet: '/api/v1/crons/lite-wallet',
    agenceStats: '/api/v1/wallet/get-agency-wallet-stats',
    agenceHistorique: '/api/v1/wallet/get-wallets-transactions',
    agenceHistoriquePaginate: '/api/v1/wallet/get-paginated-wallets-transactions',
    allValidation: '/api/v1/accounting/get-all-transfer-for-validation',
    validateTransfere: '/api/v1/accounting/validate-transfer',
    allBank: '/api/v1/wallet/get-all-bank',
    allWalletControle: '/api/v1/wallet/get-all-wallet-control',
    allControlePaginate: '/api/v1/wallet/get-all-paginated-wallet-control',
    doControle: '/api/v1/wallet/wallet-control',
    allControlTrans: '/api/v1/wallet/get-not-control-data',
    getOrder: '/api/v1/utility/get-order',
    historique: '/api/v1/wallet/get-wallet-transfer',
    allDataDeposit: '/api/v1/wallet/get-ready-for-deposit',
    remise: '/api/v1/wallet/bank-deposit',
    remiseAgence: '/api/v1/wallet/agency-deposit',
    retrait: '/api/v1/wallet/withdraw',
    transfereWallet: '/api/v1/wallet/agency-transfer',
    allCheque: '/api/v1/wallet/get-not-transfer-bank-check',
    allMomo: '/api/v1/wallet/get-not-transfer-mobile-money',
    userWallet: '/api/v1/wallet/get-user-wallet',
    banqueHistorique: '/api/v1/wallet/get-all-bank-transaction',
    banqueHistoriquePaginate: '/api/v1/wallet/get-all-bank-paginated-transaction',
    virement: '/api/v1/wallet/bank-transfer',
    specialAgence: '/api/v1/wallet/get-department-transaction',
    specialAgenceParginate: '/api/v1/wallet/get-department-paginated-transaction',
    specialStat: '/api/v1/wallet/get-department-or-linked-stats',
    imprimeFiche: '/api/v1/wallet/get-wallet-form',
    virementEntrant: '/api/v1/wallet/bank-no-sender-transfer',
    getSupplier: '/api/v1/supplier/get-supplier',
    makeExpense: '/api/v1/expense/create-expense',
    simpleTransfere: '/api/v1/wallet/simple-transfer',
    momoOnBank: '/api/v1/wallet/bank-deposit',
    updateTransfert: '/api/v1/wallet/update-wallet-transaction'
}
